/**
 * App Settings
 */
const settings = {
  night_mode: false,
  spotlight_mode: false,
  show_section_lines: true,
  sidebar_dark: false,
  sidebar_static: false,
  sidebar_small: false,
  sidebar_effect: "shrink",
  nav: true,
  nav_dark: false,
  nav_logo_path: require("../common-assets/images/logo.svg"),
  nav_logo_white_path: require("../common-assets/images/logo-white.svg"),
  nav_logo_width: "45px",
  nav_logo_url: "/",
  nav_align: "left",
  nav_expand: "lg",
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  home_url: "/",
  navigation: {
    
  },
  navigation_sidebar: {
    "/Job-Category": {
      label: "Get Started",
      name: "Job Category",
      icon: "briefcase",
    },
    "/details": {
      name: "Details",
      icon: "archive",
    },
    "/material": {
      name: "Material",
      icon: "server",
    },
    "/property": {
      name: "Property",
      icon: "layers",
    },
    "/contractor": {
      name: "Contractor",
      icon: "user",
    },
    "/survey": {
      name: "Survey",
      icon: "file-text",
    },
    "/complaint": {
      name: "Complaint",
      icon: "message-square",
    },
    "/tutorial": {
      name: "Tutorial",
      icon: "youtube",
    },
    "/notification": {
      name: "Notification",
      icon: "info",
    },
    "/supplier": {
      name: "Supplier",
      icon: "users",
    },
    "/withdrawal-request": {
      name: "Withdrawal Request",
      icon: "dollar-sign",
    },
    "/banners": {
      name: "Banners",
      icon: "tv",
    },
    "/user-list": {
      name: "User List",
      icon: "user",
    },
    "/job-list": {
      name: "Job List",
      icon: "briefcase",
    },
    "/settings": {
      name: "Settings",
      icon: "check"
    }
  },
  
  img_country: {
    usa: require("../common-assets/images/flags/united-states-of-america.svg"),
    china: require("../common-assets/images/flags/china.svg"),
    germany: require("../common-assets/images/flags/germany.svg"),
    japan: require("../common-assets/images/flags/japan.svg"),
    spain: require("../common-assets/images/flags/spain.svg"),
    france: require("../common-assets/images/flags/france.svg"),
    canada: require("../common-assets/images/flags/canada.svg"),
    netherlands: require("../common-assets/images/flags/netherlands.svg"),
    italy: require("../common-assets/images/flags/italy.svg"),
    russia: require("../common-assets/images/flags/russia.svg"),
    czech_republic: require("../common-assets/images/flags/czech-republic.svg"),
  },
  img_file: {
    empty: require("../common-assets/images/icon-empty.svg"),
    zip: require("../common-assets/images/icon-zip.svg"),
    rar: require("../common-assets/images/icon-rar.svg"),
    html: require("../common-assets/images/icon-html.svg"),
    php: require("../common-assets/images/icon-php.svg"),
    css: require("../common-assets/images/icon-css.svg"),
    js: require("../common-assets/images/icon-js.svg"),
    doc: require("../common-assets/images/icon-doc.svg"),
    txt: require("../common-assets/images/icon-txt.svg"),
    pdf: require("../common-assets/images/icon-pdf.svg"),
    xls: require("../common-assets/images/icon-xls.svg"),
    png: require("../common-assets/images/icon-png.svg"),
    jpg: require("../common-assets/images/icon-jpg.svg"),
  },
  users: [
    {
      img: require("../common-assets/images/avatar-1.png"),
      img_profile: require("../common-assets/images/avatar-1-profile.png"),
      name: "Jack Boyd",
    },
    {
      img: require("../common-assets/images/avatar-2.png"),
      name: "Helen Holt",
    },
    {
      img: require("../common-assets/images/avatar-3.png"),
      name: "Avice Harris",
    },
    {
      img: require("../common-assets/images/avatar-4.png"),
      name: "Anna Rice",
    },
    {
      img: require("../common-assets/images/avatar-5.png"),
      name: "Amber Smith",
    },
    {
      img: require("../common-assets/images/avatar-6.png"),
      name: "Mary Rose",
    },
  ],
  letters: [
    {
      img: require("../common-assets/images/letter-1.png"),
      img_min: require("../common-assets/images/letter-1-min.png"),
    },
    {
      img: require("../common-assets/images/letter-2.png"),
      img_min: require("../common-assets/images/letter-2-min.png"),
    },
    {
      img: require("../common-assets/images/letter-3.png"),
      img_min: require("../common-assets/images/letter-3-min.png"),
    },
    {
      img: require("../common-assets/images/letter-4.png"),
      img_min: require("../common-assets/images/letter-4-min.png"),
    },
  ],
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
  const isTrue = $_GET[name] === "1";

  switch (name) {
    case "setting-night-mode":
      settings.night_mode = isTrue;
      break;
    case "setting-show-section-lines":
      settings.show_section_lines = isTrue;
      break;
    case "setting-sidebar-small":
      settings.sidebar_small = isTrue;
      break;
    case "setting-sidebar-dark":
      settings.sidebar_dark = isTrue;
      break;
    case "setting-nav-dark":
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
