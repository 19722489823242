import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Card, Row, Col, Button, Input, FormFeedback, Form, Label, InputGroup, InputGroupText } from 'reactstrap';
import Logo from "../../Assets/logo.png";
import { otpVerficationService } from '../../services/DeleteAccount';
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast from 'react-hot-toast';


const DeletePage = () => {
  ;
  const history = useHistory()
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      mobile: ''
    }
  })


  const handleOtp = async (data) => {

    try {
      setLoading(true);
      const { mobile } = data;

      const result = await otpVerficationService(mobile).catch((error) => {
        setLoading(false);
        if (error.response) {
          // Server responded with a status other than 200 range
          const { code, errorMessage } = error.response.data;
          if (code === 400) {
            console.log(errorMessage)
            toast.error(errorMessage);
          } else {
            // Handle other error codes accordingly
            toast.error('An unexpected error occurred.');
          }
        } else if (error.request) {
          // Request was made but no response received
          console.error('No response from server:', error.request);
          toast.error('No response from server. Please try again later.');
        } else {
          console.error('Error setting up the request:', error.message);
          toast.error('An error occurred while processing your request.');
        }
        return;
      });

      if (result?.data?.success === true) {
        setLoading(false);
        toast.success('Otp Sent Succesfully')
        history.push(`/otp-verification/${mobile}/${result?.data?.data?.token}`);
      } else {
        setLoading(false);
        console.log('OTP request error:', result?.data || result);
        // toast.error(result?.data?.errorMessage || 'An error occurred.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during OTP request:', error);
      toast.error('An error occurred while processing your request.');
    }
  };



  return (
    <div >
      <Row>
        <Col>
          <div className="p-3">
            <div className="d-flex flex-column align-items-center justify-content-center text-center">
              <img src={Logo} alt="logo" width={150} height={150} />
              <h2 className="mt-2">
                <span className="d-block d-md-inline">Tee Letrik App</span>
              </h2>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="p-2 pt-1 h-100 mx-auto">
        <Col sm="12" md="12" className="ms-1">
          <h2>My Contractor Account Deletion</h2>
        </Col>
        <Col md sm="12" className="ms-1 mb-2 pe-2">
          <Card className="h-100" style={{ border: 'none' }}>
            <div className="h-100 container" style={{padding: '2rem'}}  >
              <h4 className="mb-2">
                These data will be deleted when you delete the account
              </h4>
              <ul className="deletion-data">
                <li>Bookings</li>
                <li>Profile Details</li>
                <li>Wallet History</li>
                <li>Job Management</li>
                <li>Job Details</li>
                <li>Strings</li>
              </ul>
            </div>
          </Card>
        </Col>
        <Col md sm="12" className="ms-1 mb-2 pe-2">
          <Card className="h-100" style={{ border: 'none' }}>
            <div className="bg-white h-100 container" style={{padding: '2rem'}}>
              <h4 className="mb-5">
                Enter your Registered{' '}
                <span className="text-primary">Phone Number</span> to{' '}
                delete your My Contractor account
              </h4>

              <Form onSubmit={handleSubmit(handleOtp)}>
                <div className="d-flex flex-column" >
                  <Label className='form-label' for='phoneNumber' >
                    <h4 style={{ marginTop: '8px' }}>Phone Number</h4>
                  </Label>
                  <InputGroup  >
                    <InputGroupText className="bg-white" >
                      +6
                    </InputGroupText>
                    <Controller
                      name='mobile'
                      control={control}
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value: /^\d{9,11}$/,
                          message: 'Phone number must be between 9 to 11 digits'
                        }
                      }}

                      render={({ field }) => (
                        <Input
                          type='tel'
                          {...field}
                          placeholder="Enter here"
                          invalid={errors.mobile && true}
                          style={{ outline: 'none' }}
                          
                        />
                      )}
                    />
                  
                  {errors.mobile && (
                    <FormFeedback>{errors.mobile.message}</FormFeedback>
                  )}
                  </InputGroup>
                  <div className="align-self-end mt-50">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={loading}
                      style={{paddingLeft: '20px', paddingRight: '20px'}}
                    >
                      {loading ? 'Verifying...' : 'Verify'}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default DeletePage
