import axios from "axios";
import { baseUrl } from "../utils/urls";

export const otpVerficationService = (mobile) => {
    return axios.post(`${baseUrl}deleteAcc/user/sendOTP`, { mobile }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    });
  };

export const deleteAccountService = (requestedData, authtoken) => axios.post(`${baseUrl}deleteAcc/user/verifyOTP`,
  requestedData,
{
    headers: {
        Accept: 'application/json',
        "Authorization": `Bearer ${authtoken}`,
        'Access-Control-Allow-Origin': '*'
    }
})