import axios from "axios";
import { baseUrl } from "../utils/urls";
import { getAuthHeaders } from '../utils';

// Contractor api 
export const contractorJobReviews = async ({reviewId, page}) => {
  try{
    const result = await axios.get(`${baseUrl}job/list/review?id=${reviewId}&page=${page}&limit=10&filter=`, { headers: getAuthHeaders() })
    return result;
  }catch(err) {
    const { response } = err
    return response;
  }
};

export const allContractorList = async ({status, page, countPerPage}) => {
  try{
    const result = await axios.get(`${baseUrl}user/manage/list/contractor?filter=${status}&page=${page}&limit=${countPerPage}`, { headers: getAuthHeaders() })
    return result;
  }catch(err) {
    const { response } = err
    return response;
  }
};

export const approveUserData = async (data) => {
  try{
    const result = axios.post(`${baseUrl}user/manage/approve`, data, { headers: getAuthHeaders() })
    return result;
  }catch(err) {
    const { response } = err
    return response;
  }
};

export const enableUserData = async (id, bool) => {
  try{
    const result = await axios.get(`${baseUrl}user/manage/status?id=${id}&status=${bool}`, { headers: getAuthHeaders() })
    return result;
  }catch(err) {
    const { response } = err
    return response;
  }
};

export const updateContractor = async (data, id) => {
  try{
    const result = await axios.post(`${baseUrl}user/manage/update?id=${id}`, data, { headers: getAuthHeaders() });
    return result;
  }catch(err) {
    const { response } = err
    return response;
  }
};