/* eslint-disable react/display-name*/
import React, {useState, useEffect} from 'react';
import { Fragment } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { contractorJobReviews } from '../../services/Contractor';
import "./style.scss";

const Content = () => {

    const [reviewList, setReviewList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalEntry, setTotalEntry] = useState(0);

    const {pathname} = useLocation();
    const id = pathname.replace(/\/contractor-review\//g,'').replace(/\//g, '');
   
    const fetchData = async(reviewId) => {
        const result = await contractorJobReviews({reviewId, page});
        if (result?.data?.success) {
            setLoading(false)
            setReviewList(result.data.data?.list)
            setTotalEntry(result?.data?.data?.count)
        }else{
            setLoading(false);
            Swal.fire({
                title: 'Error!',
                text: result?.data?.errorMessage || 'Something went wrong',
                icon: 'error',
                confirmButtonText: 'Try Again',
                confirmButtonColor: '#5b44b7',
            });
        }
    }

    useEffect(()=>{
        const authtoken = Cookies.get("rui-auth-token");
        if(authtoken){
            fetchData(id)
        }
    },[id])

    const ExpandableComponent = ({ data }) =>{
        console.log("data", data)
        return(
        <div style={{ backgroundColor: "rgb(240 236 255)", textAlign: "center" }}>
            <div className="row justify-content-start p-10">
                <div className="col-3" style={{ textAlign: "left" }}>
                    <h3 className="mt-5">
                        <strong>Review ID: </strong>
                    </h3>
                    {data?.id}
                </div>
                <div className="col-3" style={{ textAlign: "left" }}>
                    <h3 className="mt-">
                        <strong>Review: </strong>
                    </h3>
                    {data?.review}
                </div>
                <div className="col-3" style={{ textAlign: "left" }}>
                    <h3 className="mt-5">
                        <strong>Rating: </strong>
                    </h3>
                    {data?.rating}
                </div>
                <div className="col-3" style={{ textAlign: "left" }}>
                    <h3 className="mt-5">
                        <strong>Updated Date: </strong>
                    </h3>
                    {moment(data?.updatedAt).format("DD-MM-YYYY hh:mm a")}
                </div>
            </div>
        </div>)}

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Review",
            selector: (row) => row.review,
            sortable: true,
        },
        {
            name: "Rating",
            selector: (row) => row.rating,
        },
        {
            name: "Review Date",
            selector: (row) => moment(row.reviewDate).format("DD-MM-YYYY hh:mm a"),
        }
    ];

    return (
        <Fragment>
            <div className="rui-filemanager job-section">
                <div className="rui-filemanager-content">
                    <div className="table-responsive-lg mb-20">
                        {
                            reviewList.length === 0 && loading ?
                            <div className="spinner-border text-primary m-10" role="status">
                                <span className="visually-hidden"></span>
                            </div> :
                            reviewList.length === 0 && !loading ?
                                <div className="text-primary m-10" role="status">
                                    <span className="visually-hidden">No Review List Here</span>
                                </div> :
                                <DataTable
                                    className="rui-datatable rui-filemanager-table table mb-20 mt-10"
                                    columns={columns}
                                    data={reviewList}
                                    key={reviewList?.id}
                                    highlightOnHover={true}
                                    expandableRows
                                    expandableRowsComponent={<ExpandableComponent />}

                                    
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalEntry}
                                    paginationPerPage={10}
                                    paginationComponentOptions={{
                                    noRowsPerPage: true
                                    }}
                                    onChangePage={page => setPage(page)}
                                    />
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Content;