/**
 * Internal Dependencies
 */

import AuthSignIn from './AuthSignIn';
import JobType from './JobType';
import Contractor from './Contractor'
import Survey from './Survey';
import Complaint from './Complaint';
import ContractorReview from './ContractorReview';
import Material from './Material';
import Property from './Property';
import Details from './Details';
import Tutorial from './Tutorial';
import CatTutorial from './CatTutorial';
import Notification from './Notification';
import Supplier from './Supplier';
import WithdrawalBank from './WithdrawalBank';
import Banners from './Banners';
import UserList from './UserList';
import JobList from './JobList';
import Settings from './Settings';
import DeletePage from './DeleteAccount/content';
import OtpVerfication from './DeleteAccount/content1'
import SucessPage from './DeleteAccount/sucess'
   
export default {
    '/delete-account': DeletePage,
    '/otp-verification/:mobile/:token': OtpVerfication,
    '/success-page': SucessPage,
    '/sign-in': AuthSignIn,
    '/': JobType,
    '/Job-Category': JobType,
    '/contractor': Contractor,
    '/survey': Survey,
    '/complaint': Complaint,
    '/contractor-review/:id': ContractorReview,
    '/material': Material,
    '/property': Property,
    '/details': Details,
    '/tutorial': Tutorial,
    '/category-tutorial/:id': CatTutorial,
    '/notification': Notification,
    '/supplier': Supplier,
    '/withdrawal-request': WithdrawalBank,
    '/banners': Banners,
    '/user-list': UserList,
    '/job-list': JobList,
    '/settings': Settings
};
