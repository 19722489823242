import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Input, Row, Col } from 'reactstrap';
import toast from 'react-hot-toast';
import { otpVerficationService, deleteAccountService } from '../../services/DeleteAccount'; 
import Logo from "../../Assets/logo.png";
import { Mail } from 'react-feather';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const VerificationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { mobile, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [otpInputs, setOtpInputs] = useState(['', '', '', '']);
  const [authtoken, setAuthToken] = useState(token);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(60); // Initial value in seconds
  const inputRefs = Array.from({ length: 4 }, () => useRef(null));

  // Effect to decrement the timer every second
  useEffect(() => { 
    let interval;

    if (resendTimer > 0 && resendDisabled) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
  
    return () => {
      clearInterval(interval);
    };
  }, [resendTimer, resendDisabled]);

  const handleOtp = async () => {
    setLoading(true);

    const otpValue = otpInputs.join('');
    if (!otpValue.trim()) {
      toast.error('Please fill in the OTP field.');
      setLoading(false);
      return;
    }
   
    const requestedData = {
       otpType:"Delete Account",
       otp: otpValue
    }

    try {
      const result = await deleteAccountService( requestedData, authtoken );

      if (result?.data?.success === true) {
        setLoading(false);
        toast.success('OTP verified successfully');
        history.push({
          pathname: '/success-page'
        });      
      } else {   
        setLoading(false);
        toast.error('Incorrect OTP. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during OTP verification:', error);
      toast.error('Failed to verify OTP. Please try again.');
    }
  };

  const handleResend = async () => {
    try {
      setLoading(true);

      const result = await otpVerficationService(mobile);

      if (result?.data?.success === true) {
        setLoading(false);
        toast.success('OTP resent successfully');
        setResendDisabled(true);
        setResendTimer(60);
        setAuthToken(result?.data?.data?.token)
      } else {
        setLoading(false);
        toast.error(result.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during OTP resend:', error);
      toast.error('Failed to resend OTP. Please try again.');
    }
  };

  const handleInputChange = (index, value) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;
    setOtpInputs(newOtpInputs);

    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleCancel = () => {
    history.push('/delete-account');
  };

  return (
    <>
      <Row>
        <Col>
          <div className="p-3">
            <div className="d-flex flex-column align-items-center justify-content-center text-center">
              <img src={Logo} alt="logo" width={150} height={150} />
              <h2 className="mt-2">
                <span className="d-block d-md-inline">Tee Letrik</span>
              </h2>
            </div>
          </div>
        </Col>
      </Row>
      <Row className='m-1 mt-50'>
        <div className="d-flex justify-content-center mt-1">
          <div className="bg-white p-5 d-flex flex-column align-items-center justify-content-center shadow col-lg-5 col-md-8 col-12">
            <div>
              <div className='text-center mb-1'><Mail /></div>
              <h5 className="fw-bold mt-2 mb-0 text-center">Please check your Phone Number</h5>
              <p className='text-center mb-0 mt-0 font-resend'>We have sent a code to +6 {mobile || '--'}</p>
            </div>
            <Row className="mb-1 mt-1">
              {Array.from({ length: 4 }, (_, index) => (
                <Col key={index} xs="auto" className="p-0 me-1">
                  <Input
                    className="otp-field rounded-3 mb-2 text-center"
                    type="number"
                    value={otpInputs[index]}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 1) e.preventDefault();
                    }}
                    innerRef={inputRefs[index]}
                  />
                </Col>
              ))}
            </Row>
            <div style={{marginTop: '10px'}}>
              <p className='font-resend'>
                Did not get code{' '}
                {resendDisabled ? (
                  resendTimer > 0 ? (
                    <span>
                      Resend in {resendTimer} seconds
                    </span>
                  ) : (
                    <strong
                      onClick={handleResend}
                      style={{ cursor: 'pointer' }}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleResend();
                        }
                      }}
                    >
                      Click to resend
                    </strong>
                  )
                ) : null}
              </p>
            </div>

            <Row >
              <Col className='mb-2 mb-lg-0 me-2'>
                <Button
                  color="outline-secondary"
                  className="col-12"
                  onClick={handleCancel}
                  style={{paddingLeft: '20px', paddingRight: '20px'}}
                >
                  Cancel
                </Button>
              </Col>
              <Col  className="mb-2 mb-lg-0">
                <Button
                  color="primary"
                  className="col-12"
                  onClick={handleOtp}
                  style={{paddingLeft: '20px', paddingRight: '20px'}}
                >
                  Verify
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </>
  );
};

export default VerificationPage;
