/**
 * External Dependencies
 */
import React from "react";
import { useLocation } from "react-router-dom";

/**
 * Internal Dependencies
 */
import PageWrap from "../../components/page-wrap";
import PageTitle from "../../components/page-title";
import PageContent from "../../components/page-content";
import Content from "./content";
/**
 * Component
 */
const ContractorReview = () => {
  const { state } = useLocation();
  return (
    <PageWrap>
      <PageTitle>
        <h1>Review</h1>
      </PageTitle>
      <PageContent>
        <Content ReviewList={state?.Reviews} />
      </PageContent>
    </PageWrap>
  );
};

export default ContractorReview;
